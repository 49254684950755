import { useState, useEffect, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function DeliveryDriversDrivingQuestionsPage({userId,registrationNumber, setRegistrationNumber,
       vehicleType, setVehicleType, category,
        setCategory, fuelType, setFuelType}) {

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [response, error2,loading, axiosFetch] = useAxiosFunction();

  const [result, setResult] = useState(false);

  useLayoutEffect(() => {
    if(result){
      submitAction();
    }
    
  }, [result])


  const [vehicleTypeError, setVehicleTypeError] = useState(false);
  const [vehicleTypeText, setVehicleTypeText] = useState('');

  const [fuelTypeError, setFuelTypeError] = useState(false);
  const [fuelTypeText, setFuelTypeText] = useState('');

  const [registrationNumberError, setRegistrationNumberError] = useState(false);
  const [registrationNumberText, setRegistrationNumberText] = useState('');

  const [categoryError, setCategoryError] = useState(false);
  const [categoryText, setCategoryText] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);


  
  const [hideFuel, setHideFuel] = useState(false);

  useEffect(() => {
    if(vehicleType == "van"){
      //show fuel type and van categories
      setCategoryOptions([
        {value:"small", label:"Class I (up to 1.305 tonnes)"},
        {value:"medium", label:"Class II (1.305 to 1.74 tonnes)"},
        {value:"large", label:"Class III (1.74 to 3.5 tonnes)"}
      ]);
      setHideFuel(true);
      
    }
    else{
      //hide fuel type and van categories
      setHideFuel(false);
      setFuelType(null);
      setCategoryOptions([
        {value:"Rigid_1", label:"Rigid (>3.5 – 7.5 tonnes)"},
        {value:"Rigid_2", label:"Rigid (>7.5 – 17 tonnes)"},
        {value:"Rigid_3", label:"Rigid (>17 tonnes)"},
        {value:"Articulated_1", label:"Articulated (>3.5 – 33t)"},
        {value:"Articulated_2", label:"Articulated (>33t)"}
      ]);

    }

    
  }, [vehicleType])


  const handleVehicleTypeChange = (event) => {
        setVehicleType(event.target.value);
    };

    const handleFuelTypeChange = (event) => {
        setFuelType(event.target.value);
    };

  const handleRegistrationNumberChange  = (newValue) => {
      setRegistrationNumber(newValue);
  };
  const handleCategoryChange = (event) => {
      setCategory(event.target.value);
  };

  var reg_regex = /^[a-zA-Z0-9 ]{1,50}$/;

  const nextPage = () =>{

    if(vehicleType !== null && vehicleType !== "" && registrationNumber !== null && registrationNumber !== "" && category !== null && category !== ""){

      if(vehicleType == 'van' && fuelType == null && fuelType == ""){
        setFuelTypeText('Please select a valid fuel type.');
        setFuelTypeError(true);
        return;
      }


      //check registration regex
      setVehicleTypeText('');
      setVehicleTypeError(false);
      setFuelTypeText('');
      setFuelTypeError(false);
      setRegistrationNumberText('');
      setRegistrationNumberError(false);
      setCategoryText('');
      setCategoryError(false);

      console.log("reg_regex.test(registrationNumber): "+reg_regex.test(registrationNumber))

      if(!reg_regex.test(registrationNumber)){
        setRegistrationNumberText('Please enter a valid vehicle registration number containing only letters, numbers and spaces.');
        setRegistrationNumberError(true);
        return;
      }


      submit();
    }
    else{
      setVehicleTypeText('');
      setVehicleTypeError(false);
      setFuelTypeText('');
      setFuelTypeError(false);
      setRegistrationNumberText('');
      setRegistrationNumberError(false);
      setCategoryText('');
      setCategoryError(false)

      if(vehicleType === null || vehicleType === ""){
        setVehicleTypeText('Please select a valid vehicle type.');
        setVehicleTypeError(true);
      }
      else{
        if(vehicleType == 'van'){
          if(fuelType === null || fuelType === ""){
            setFuelTypeText('Please select a valid fuel type.');
            setFuelTypeError(true);
          }

        }
      }

      

      if(registrationNumber === null || registrationNumber === ""){
        setRegistrationNumberText('Please enter a valid vehicle registration number containing only letters, numbers and spaces.');
        setRegistrationNumberError(true);
      }

      if(category === null || category=== ""){
        setCategoryText('Please select a valid category.');
        setCategoryError(true);
      }


    }
  }

  const submit = async e => {
    const bodyObj = JSON.stringify({
      "userId":userId,
      "registrationNumber":registrationNumber,
      "vehicleType":vehicleType,
      "category":category,
      "fuelType":fuelType
    });

    try {
      await axiosFetch({
        axiosInstance: axios,
        method: 'post',
        url: '/updateDeliveryVehicleInfo',
        requestConfig: {
          headers: {
            'Content-Type': 'application/json',
          },
          data: bodyObj
        }

      })
    } catch (err) {
      console.log(err);

    }
    setResult(true);
  }

  function submitAction() {
    console.log("RESPONSE: "+response)
    if (response.status === 200) {
      //clear last user info
      setVehicleType(null);
      setFuelType(null);
      setRegistrationNumber(null);
      setCategory(null);

      navigate("/delivery_drivers");
    }
  }



  return (
    <div>
        <Box className={styles.questionH1Top} >
              <h1>Vehicle Type</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={vehicleTypeError}>
              <InputLabel id="vehicle-type-label">Vehicle Type</InputLabel>
              <Select
                    labelId="vehicle-type-label"
                    value={vehicleType}
                    label="Vehicle Type"
                    onChange={handleVehicleTypeChange}
                    
                >
                    <MenuItem value={"HGV"}>HGV</MenuItem>
                    <MenuItem value={"HGV_Refrigerated"}>HGV Refrigerated</MenuItem>
                    <MenuItem value={"van"}>Van</MenuItem>
                </Select>
                  <FormHelperText>{vehicleTypeText}</FormHelperText>
              </FormControl>
              </Box>
              <Box className={styles.questionH1} >
                  <h1>Category</h1>
                  </Box>
                  <Box className={styles.textField}>
                  <FormControl fullWidth margin="normal" className={styles.field} error={categoryError}>
                  <InputLabel id="category-type-label">Category</InputLabel>
                  <Select
                        labelId="category-type-label"
                        value={category}
                        label="Category"
                        onChange={handleCategoryChange}
                    >
                        {categoryOptions.map((categoryOption) =>
                            <MenuItem key={categoryOption.value} value={categoryOption.value}>{categoryOption.label}</MenuItem>
                        )}
                    </Select>
                      <FormHelperText>{categoryText}</FormHelperText>
                  </FormControl>
                  </Box>

              {hideFuel?<>
                <Box className={styles.questionH1} >
                  <h1>Fuel Type</h1>
                  </Box>
                  <Box className={styles.textField}>
                  <FormControl fullWidth margin="normal" className={styles.field} error={fuelTypeError}>
                  <InputLabel id="fuel-type-label">Fuel Type</InputLabel>
                  <Select
                        labelId="fuel-type-label"
                        value={fuelType}
                        label="Fuel Type"
                        onChange={handleFuelTypeChange}
                    >
                        <MenuItem value={"petrol"}>Petrol</MenuItem>
                        <MenuItem value={"diesel"}>Diesel</MenuItem>
                        <MenuItem value={"lpg"}>LPG</MenuItem>
                        <MenuItem value={"p_hybrid"}>Plug-in Hybrid Electric Vehicle</MenuItem>
                        <MenuItem value={"bec"}>Battery Electric Vehicle</MenuItem>
                    </Select>
                      <FormHelperText>{fuelTypeText}</FormHelperText>
                  </FormControl>
                  </Box>
              </>:<>
              
              
              </>}
              <Box className={styles.questionH1}>
              <h1>Vehicle Registration</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={registrationNumberError}>
                 <TextField error={registrationNumberError} autoComplete='off' value={registrationNumber}  fullWidth id="outlined-basic" label="Vehicle Registration" variant="outlined" onChange={e => handleRegistrationNumberChange(e.target.value)}  />
                 <FormHelperText>{registrationNumberText}</FormHelperText>
              </FormControl>
              </Box>



              
              <Box className={btnStyles.navbuttons} >
              <Button className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

    </div>
  );
}

