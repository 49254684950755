import { useState, useEffect, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function DeliveryDriversPage({userId, siteId, singleDrop, setSingleDrop, lastDrop, setLastDrop}) {

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [response, error, loading, axiosFetch] = useAxiosFunction();

  const [singleDropError, setSingleDropError] = useState(false);
  const [singleDropText, setSingleDropText] = useState('');

  const [lastDropError, setLastDropError] = useState(false);
  const [lastDropText, setLastDropText] = useState('');

  const [hideLastDrop, setHideLastDrop] = useState(false);


  const [checkSitePostcodeResult, setCheckSitePostcodeResult] = useState(false);
  const [checkValidPostcodeResult, setCheckValidPostcodeResult] = useState(false);
  useLayoutEffect(() => {
    if(checkSitePostcodeResult){
      checkAction();
    }
      
      return()=>{
        setCheckSitePostcodeResult(false);
      }
    }, [checkSitePostcodeResult]);
  
    useLayoutEffect(() => {
      if(checkValidPostcodeResult){
        checkAction2();
      }
      
      return()=>{
        setCheckValidPostcodeResult(false);
      }
    }, [checkValidPostcodeResult]);

  useEffect(() => {

    console.log(singleDrop);

      if(singleDrop == 'false'){
        setHideLastDrop(true);
        
      }
      else{
        setLastDrop(null);
        setHideLastDrop(false);
      }
  
      
    }, [singleDrop]);

  const singleDropChange = (e) =>{
    setSingleDrop(e.target.value);
  }

  const handleLastDropChange = (newValue) => {
    setLastDrop(newValue);
  }

  function checkAction(){
    if(response.status === 200){
      let res = response.sitePostcodeCheck;

      if(res === true){
        setLastDropText('Please enter the postcode of the drop before this site, not the site postcode.');
        setLastDropError(true);
      }
      else{
        //check valid postcode
        checkValidPostcode();
      }


    }


  }

  const checkSitePostcode = async e =>{
    try {
          await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/checkIfSitePostcode',
            requestConfig: {
              headers: {
                'Content-Type': 'application/json',
              },
              data: {
                "postcode":lastDrop,
                "siteId":siteId,
                "id":userId
            }
            }
    
          })
        } catch (err) {
          console.log(err);
    
        }
    
      setCheckSitePostcodeResult(true);



  }
  function checkAction2(){
    console.log(response.status)
    if(response.status === 200){
      let res = response.validPostcodeCheck;
      console.log("res: "+res);
      if(res === true){
        navigate("/site-rules");
      }
      else{
        //check valid postcode
        setLastDropText('Your postcode cannot be verified. Please amend the ‘Postcode’ field before trying to submit this page again.');
        setLastDropError(true);
      }


    }


  }
  const checkValidPostcode = async e =>{
    try {
          await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/checkIfPostcodeValid',
            requestConfig: {
              headers: {
                'Content-Type': 'application/json',
              },
              data: {
                "postcode":lastDrop,
                "siteId":siteId,
                "id":userId
            }
            }
    
          })
        } catch (err) {
          console.log(err);
    
        }
    
      setCheckValidPostcodeResult(true);



  }


  var post_code_regex = /^[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?\s?[0-9][a-zA-Z]{2}$/;
  const nextPage = () =>{
    if(singleDrop !== null && singleDrop != ''){
      setSingleDropText('');
      setSingleDropError(false);
      setLastDropText('');
      setLastDropError(false);

      if(singleDrop == 'false'){
        console.log(lastDrop)
        if(lastDrop != "" && lastDrop != null){
          //check postcode regex
          let ld = lastDrop.trim().replaceAll(/\s/g,'');
          if(!post_code_regex.test(ld)){
            setLastDropText('Please enter a valid postcode.');
            setLastDropError(true);
          }
          else{
            //check if site postcode
            checkSitePostcode();
          }
          
          

          //check if exists on mapbox

        }
        else{
          navigate("/site-rules");

        }

      }
      else{
        navigate("/site-rules");
      }


      
    }
    else{

      setSingleDropText('');
      setSingleDropError(false);
      setLastDropText('');
      setLastDropError(false);

      if(singleDrop == null || singleDrop == ''){
        setSingleDropText('Please select an option.');
        setSingleDropError(true);
      }


    }




    

  }



  return (
    <div>
        <>
      <Box className={styles.questionH1Top} >
        <h1>Is this a single drop or multi-drop delivery?</h1>
        </Box>
        <Box className={styles.radioGroup}>
        <FormControl sx={{ m: 3 }} error={singleDropError} variant="standard">
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={singleDrop}
        onChange={singleDropChange}
      >
        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={true} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="Single Drop" />
        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={false} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="Multi-Drop" />
      </RadioGroup>
      <FormHelperText>{singleDropText}</FormHelperText>
    </FormControl>
    </Box>

    {hideLastDrop?<>
                <Box className={styles.questionH1} >
                  <h1>Postcode of last drop before Dandara site</h1>
                  </Box>
                  <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={lastDropError}>
                 <TextField error={lastDropError} autoComplete='off' value={lastDrop}  fullWidth id="outlined-basic" label="Last Drop Postcode" variant="outlined" onChange={e => handleLastDropChange(e.target.value)}  />
                 <FormHelperText>{lastDropText}</FormHelperText>
              </FormControl>
              </Box>
              </>:<>
              
              
              </>}

     
      </>
      <Box className={btnStyles.navbuttons} >
              <Button className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>
    </div>
  );
}

