import { useState, useEffect} from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import {Routes, Route, useNavigate } from 'react-router-dom';
import AppBar from './components/AppBar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Home from './routes/Home';
import SitePage from './routes/SitePage';
import UsersInformation from './routes/UsersInformation';
import ActionPage from './routes/ActionPage';
import DeliveryDriversPage from './routes/DeliveryDriversPage';
import DeliveryDriversDrivingQuestionsPage from './routes/DeliveryDriversDrivingQuestionsPage';
import DrivingPage from './routes/DrivingPage';
import DrivingQuestionsPage from './routes/DrivingQuestionsPage';
import Success from './routes/Success';
import Error from './routes/ErrorPage';
import SiteRules from './routes/SiteRules';


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light:'#72c8bd',
        main: '#4FBAAD',
        dark:'#47a79c'
      },
      secondary:{
        light:'#ed7148',
        main:'#E94E1B',
        dark:'#a33612',
      },
      error:{
        light:'#ed7148',
        main:'#E94E1B',
        dark:'#a33612',
      }
    },
  });

 
  const [siteId, setSiteId] = useState(null);
  const [siteName, setSiteName] = useState(null);

  const [userId, setUserId] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [operativeEmployer, setOperativeEmployer] = useState(null);
  const [drivingToSite, setDrivingToSite] = useState(false);
  const [asDriver, setAsDriver] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const [signIn, setSignIn] = useState(false);
  const [signOut, setSignOut] = useState(false);
  const [isDrivingToday, setIsDrivingToday] = useState(false);

  const [askDrivingQue, setAskDrivingQue] = useState(false);

  const [buttonToShow, setButtonToShow] = useState(null);

  const [drivingToSiteNew, setDrivingToSiteNew] = useState(false);
  const [asDriverNew, setAsDriverNew] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState(null);

  const [transportType, setTransportType]  = useState(null);
  const [transportSize, setTransportSize]  = useState(null);
  const [fuelType, setFuelType] = useState(null);

  const [vehicleType, setVehicleType]  = useState(null);
  const [category, setCategory]  = useState(null);

  const [isDeliveryDriver, setIsDeliveryDriver] = useState(false);
  const [missingDDVehicleInfo, setMissingDDVehicleInfo] = useState(false);
  const[singleDrop, setSingleDrop] = useState(null);
  const[lastDrop, setLastDrop] = useState(null);


  const navigate = useNavigate();
  const current_url = window.location.pathname;

  // this function will work only when you do reload. 
    window.onbeforeunload = (event) => {
      event.preventDefault();
      localStorage.setItem("page",current_url) // Store the page URL 
    };
  
  // After first redirection and due to bounce effect will come back to current page.
    useEffect(() => {
    
    // if your localstorage have the key "page and the value is the current page...
      if(localStorage.getItem("page") === current_url){
        
        localStorage.removeItem("page"); // also you have to remove the item from localstorage. 
        navigate("/") // ... then it will redirect you to somewhere you wish.
      }
    },[])

  return (

    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <AppBar />
          <Routes>
            <Route path="/" element={<Home siteId={siteId} setSiteId={setSiteId} />} />
            <Route path="/site/:id" element={<SitePage siteId={siteId} setSiteId={setSiteId} siteName={siteName} setSiteName={setSiteName} userId={userId} setUserId={setUserId} />} />
            <Route path="/user" element={<UsersInformation siteId={siteId} userId={userId} setUserId={setUserId} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} operativeEmployer={operativeEmployer} setOperativeEmployer={setOperativeEmployer} drivingToSite={drivingToSite} setDrivingToSite={setDrivingToSite} asDriver={asDriver} setAsDriver={setAsDriver} isValid={isValid} setIsValid={setIsValid} buttonToShow={buttonToShow} setButtonToShow={setButtonToShow} askDrivingQue={askDrivingQue} setAskDrivingQue={setAskDrivingQue} signIn={signIn} setSignIn={setSignIn} signOut={signOut} setSignOut={setSignOut} setIsDrivingToday={setIsDrivingToday} isDeliveryDriver={isDeliveryDriver} setIsDeliveryDriver={setIsDeliveryDriver} missingDDVehicleInfo={missingDDVehicleInfo} setMissingDDVehicleInfo={setMissingDDVehicleInfo}/>} />
            <Route path="/action" element={<ActionPage signIn={signIn} setSignIn={setSignIn} signOut={signOut} setSignOut={setSignOut} siteId={siteId} userId={userId} buttonToShow={buttonToShow} setButtonToShow={setButtonToShow} askDrivingQue={askDrivingQue} setAskDrivingQue={setAskDrivingQue} setIsDrivingToday={setIsDrivingToday}/>} />
            <Route path="/driving" element={<DrivingPage userId={userId} drivingToSite={drivingToSite} asDriver={asDriver} isDrivingToday={isDrivingToday} setIsDrivingToday={setIsDrivingToday} />} />
            <Route path="/driving-questions" element={<DrivingQuestionsPage userId={userId} drivingToSite={drivingToSite} asDriver={asDriver} drivingToSiteNew={drivingToSiteNew} setDrivingToSiteNew={setDrivingToSiteNew} asDriverNew={asDriverNew} setAsDriverNew={setAsDriverNew} registrationNumber={registrationNumber} setRegistrationNumber={setRegistrationNumber} transportType={transportType} setTransportType={setTransportType} transportSize={transportSize} setTransportSize={setTransportSize} fuelType={fuelType} setFuelType={setFuelType} />} />
            <Route path="/delivery-drivers-driving-questions" element={<DeliveryDriversDrivingQuestionsPage userId={userId}  registrationNumber={registrationNumber} setRegistrationNumber={setRegistrationNumber} vehicleType={vehicleType} setVehicleType={setVehicleType} category={category} setCategory={setCategory} fuelType={fuelType} setFuelType={setFuelType}/>} />
            <Route path="/delivery_drivers" element={<DeliveryDriversPage userId={userId} siteId={siteId} singleDrop={singleDrop} setSingleDrop={setSingleDrop} lastDrop={lastDrop} setLastDrop={setLastDrop} />} />
            <Route path="/site-rules" element={<SiteRules siteId={siteId} userId={userId} isDrivingToday={isDrivingToday} setIsDrivingToday={setIsDrivingToday} signIn={signIn} signOut={signOut} singleDrop={singleDrop} setSingleDrop={setSingleDrop} lastDrop={lastDrop} setLastDrop={setLastDrop} />} />
            <Route path="/success" element={<Success siteId={siteId} siteName={siteName} firstName={firstName} lastName={lastName} signIn={signIn} signOut={signOut} setUserId={setUserId}  />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </ThemeProvider>

      </LocalizationProvider>
    </StyledEngineProvider>

  );
}

export default App;
